import { createApp } from 'vue'
import { createPinia } from 'pinia'

import NaviContainer from '@/modules/navi'

const mountElement = document.querySelector('#navi-app')
const desktopBreakpoint = parseInt(mountElement.dataset.desktopBreakpoint || '768', 10)
const shouldShowSearchPanel = mountElement.dataset.shouldShowSearchPanel !== 'false'

const naviApp = createApp(NaviContainer, { desktopBreakpoint, shouldShowSearchPanel })
naviApp.use(createPinia())
naviApp.mount(mountElement)
